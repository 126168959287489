@import "../common"
.ArtistPage
  padding: 50px 0
  img.avatar
    height: 180px
    aspect-ratio: 1
    border-radius: 50%
    border: 2px solid $accent
  img.inst
    height: 40px
    aspect-ratio: 1
  h5.name
    font-size: 36px
    color: white
    margin: 0
  p.bio
    font-size: 16px
    color: #aeaeae

  h5.tab
    color: white
    text-align: center
    font-size: 28px
    margin-inline: 0
    margin-block: 0
    margin: 20px 0

    cursor: pointer
    &:not(.active)
      color: $accent
