.LightboxModal
  .backdrop
    background: rgba(0, 0, 0, 0.80)
    backdrop-filter: blur(10px)

    position: fixed
    top: 0
    left: 0
    height: 100%
    width: 100%
    z-index: 10
    touch-action: none

    display: flex
    .container
      //  background-color: #BB73BF
      display: flex
      justify-content: center
      align-items: center
    .pic-fake
      visibility: hidden
    .pic
      &.lg
        max-height: 75vh
        max-width: 75vw
      &.sm
        max-height: 55vh
        max-width: 70vw
    .pic:not(.pic-fake)
      left: 0
      position: absolute

      transition: transform 0.5s
      &.righter
        transform: translateX(45%) scale(0.6)
        filter: brightness(50%)
      &.rightest
        transform: translateX(70%) scale(0.4)
        filter: brightness(20%)
      &.lefter
        transform: translateX(-45%) scale(0.6)
        filter: brightness(50%)
      &.leftest
        transform: translateX(-75%) scale(0.4)
        filter: brightness(20%)
    .arrow
      z-index: 1500
      cursor: pointer
      width: 80px
      &.left
        transform: rotate(90deg)
      &.right
        transform: rotate(-90deg)

[dir=rtl]
  .LightboxModal .backdrop
    .arrow
      &.left
        transform: rotate(-90deg)
      &.right
        transform: rotate(+90deg)
    .pic:not(.pic-fake)
      &.righter
        transform: translateX(-45%) scale(0.6)
      &.rightest
        transform: translateX(-70%) scale(0.4)
      &.lefter
        transform: translateX(45%) scale(0.6)
      &.leftest
        transform: translateX(75%) scale(0.4)

@media(max-width: 994px)
  .LightboxModal
    .arrow
      display: none!important
    .pic:not(.pic-fake)
      &.righter
        transform: translateX(30%) scale(0.6) !important
      &.rightest
        transform: translateX(50%) scale(0.4) !important
      &.lefter
        transform: translateX(-30%) scale(0.6) !important
      &.leftest
        transform: translateX(-50%) scale(0.4) !important

@media(max-width: 994px)
  [dir=rtl]  .LightboxModal
      .arrow
        display: none!important
      .pic:not(.pic-fake)
        &.righter
          transform: translateX(-30%) scale(0.6) !important
        &.rightest
          transform: translateX(-50%) scale(0.4) !important
        &.lefter
          transform: translateX(30%) scale(0.6) !important
        &.leftest
          transform: translateX(50%) scale(0.4) !important

@media (min-width: 1200px)
  .LightboxModal
    .backdrop
      .container .pic.sm
        max-height: 65vh
