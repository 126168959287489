.GoodCard
  //height: calc(100%)
  .goodContent
    margin: 15px
    border-radius: 5px
    //padding: 15px
    cursor: pointer
    background: #252525
    height: calc( 100%  - 30px)
    box-shadow: 6px 6px 12px rgba(0,0,0,43%)
    .content-wrapper
      height: calc( 100%  - 30px)
      display: flex
      flex-direction: column
      padding: 15px
  img.productImage
    width: 100%
    border-radius: 3px
  div.labelsRow
    color: white
    padding: 10px 0
    .qty
      font-weight: lighter
  div.mainRow
    color: white
    padding-top: 10px
    align-items: center
    margin-top: auto
    .price
      font-weight: bold
      font-size: 24px
    .name
      font-size: 20px
      font-weight: bolder
