@import '../../common'
.WelcomeSection
  .wrapper
    display: flex
    min-height: 960px
    flex-direction: column

    .welcome-first
      flex-grow: 1

      h1
        text-transform: uppercase
        font-size: 36px
        font-weight: 400
        color: white
        margin-top: 150px

      p
        font-weight: 400
        color: white
        font-size: 20px
        max-width: 600px

      .first-section-layout
        display: flex
        flex-direction: column
        height: 100%

      .buttons
        margin-top: 50px

    .welcome-icons
      h3
        @include sectionHeader

      flex-grow: 0.75

@media(max-width: 991px)
  .WelcomeSection

    .wrapper
      display: flex
      flex-direction: column

      .welcome-first
        height: calc(100svh - 50px)
        flex-grow: 1
        $l1: linear-gradient(90deg, rgba(0, 0, 0, 0.8) 0.05%, rgba(0, 0, 0, 0.6) 50%, rgba(0, 0, 0, 0.00) 100%)
        $l2: linear-gradient(90deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.50) 100%)
        background: $l1, $l2, url('../../img/tmp/img.webp') no-repeat
        background-size: cover

        h1
          margin-top: 50px

        .buttons
          margin-top: auto
          margin-bottom: 20px

      .welcome-icons
        flex-grow: 0
        h3
          display: block
  .Button

    width: 100%


@media(min-width: 992px)
  .WelcomeSection
    $l1: linear-gradient(90deg, rgba(0, 0, 0, 0.8) 0.05%, rgba(0, 0, 0, 0.6) 50%, rgba(0, 0, 0, 0.00) 100%)
    $l2: linear-gradient(90deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.50) 100%)
    background: $l1, $l2, url('../../img/tmp/img.webp') no-repeat
    background-size: cover
    .wrapper
      height: calc(100lvh - 50px)

@media(min-width: 991px ) and (max-width: 1366px)
  .WelcomeSection

      .wrapper
        .welcome-first
          flex-grow: 0
          h1
            margin-top: 50px
          p
            margin: 0
          .buttons
            margin-top: 20px
        .welcome-icons
          h3
            margin: 25px 0 0 0
