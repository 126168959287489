@import '../../common'
.QtyControl
  display: flex
  span
    font-size: 36px
    font-weight: bolder
    min-width: 50px
    color: white
    text-align: center
    border: 1px solid $accent
    padding: 0 20px
    img.cartIcon
      height: 30px
      margin-right: 7px
      margin-bottom: -2px
      aspect-ratio: 1

  button
    background: $accent
    text-align: center
    padding: 10px 10px 5px 10px
    outline: none
    border: 1px solid transparent
    box-sizing: border-box
    &[disabled]
      background: transparent
      border: #313131 solid 1px

      svg path
        fill: #313131
      &:last-of-type
        border-left: none


  button:first-of-type
    border-radius: 15px 0 0 15px
  button:last-of-type
    border-radius:  0 15px 15px 0
  svg
    width: 29px
    height: 29px
    path
      fill: #fff

@media(max-width: 994px)
  .QtyControl
    span
      font-size: 28px
      min-width: 40px
      padding: 0 10px
      img.cartIcon
        height: 25px
    button
      padding: 5px 5px 3px 5px

[dir=rtl] .QtyControl
  button:first-of-type
    border-radius:  0 15px 15px 0
  button:last-of-type
    border-radius: 15px 0 0 15px
