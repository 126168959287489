@import "../common"
.HealingPage
  padding-top: 30px
  color: white
  h3
    @include sectionHeader
    padding: 10px 0

  p
    text-indent: 25px
    font-size: 24px
  span.highlight
    font-weight: bolder
    color: $accent

  h5
    @include sectionHeader
    text-align: left
    font-size: 32px
    color: $accent
    font-weight: bolder

    span
      padding: 0 7px

  ul.do-not-do-list
    li
      display: flex
      align-items: center
      span
        font-size: 36px
        padding: 0 15px
      list-style: none
      color: $accent
      font-size: 28px
      padding: 2px 0
  ol
    li
      align-items: center
      span
        color: $accent
        padding-right: 15px
      font-size: 24px
      padding: 7px 0

  &[dir=rtl]
    h5, h3
      text-align: start
