@import "bootstrap/scss/functions"
@import "../../common"
@import "bootstrap/scss/variables"
@import "bootstrap/scss/mixins"

.Portfolio
  cursor: pointer
  width: calc(100% / 5)
  //margin-bottom: -5px
  display: flex
  //flex: 1 0 auto
  position: relative

  img.photo
    width: 100%
    aspect-ratio: auto

  img.artist
    position: absolute
    bottom: 10px
    right: 10px
    border-radius: 50%
    border: 2px solid $accent
    aspect-ratio: auto
    width: 40px

@include media-breakpoint-down(lg)
  .Portfolio
    width: calc(100% / 3)
  img.artist
    width: 60px
