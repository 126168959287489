@import "bootstrap/scss/functions"
@import "../../common"
@import "bootstrap/scss/variables"
@import "bootstrap/scss/variables-dark"

@import "bootstrap/scss/maps"
@import "bootstrap/scss/mixins"
@import "bootstrap/scss/root"
@import "bootstrap/scss/bootstrap-utilities"

.FooterSection
  width: 100%
  height: 700px
  background-color: black
  h3
    padding-top : 10px
    //padding-bottom : 20px
    @include sectionHeader

  .contacts
    .contacts-data
      //.row

      img
        //width: 32px
        //height:  32px
        aspect-ratio: 1
        height: 32px


      a
        text-align: center
        color : white
        max-width: 500px
        font-size: 26px
        font-weight: 600
        text-decoration: none
        display: block
  .working-hours
    padding: 30px 0
    display: flex
    justify-content: center

@media(max-width: 994px)
  .FooterSection iframe
      height: 350px !important