@import "../../common"
@import "../../shake"
.GoodModal
  img.pic
    border-radius: 15px
  .footer
    h3
      font-weight: bolder
      font-size: 40px
      padding: 0 10px
      text-align: center
      color: white

      margin-block: 0
      margin-inline: 0

      margin: 0.25em 0
    p
      text-align: center
      font-weight: lighter
      font-size: 30px
      color: white

      margin-block: 0
      margin-inline: 0
      margin: 0.5em 0 !important
    span.qty
      color: white

.SizesSelector
  &>span
    display: inline-flex
    justify-content: center
    font-size: 32px
    font-weight: bolder
    padding: 7px 8px
    cursor: pointer
    color: $accent
    border: $accent solid 2px

    transition: background .2s ease-in-out, color .2s ease-in-out

    &:first-of-type
      border-radius: 15px 0 0 15px
    &:last-of-type
      border-radius:  0 15px  15px 0
    &.disabled
      color: #505050
      border: #313131 solid 2px


  span.badge
    font-size: 18px
    padding: 5px
    margin-top: 9px
    margin-left: 10px
    height: 18px
    width: 18px
    color: white
    background: $accent
    border-radius: 18px
    text-align: center
    line-height: 18px
    transition: background .2s ease-in-out, color .2s ease-in-out

  .disabled
    span.badge
      color: #505050
      //border: #313131 solid 2px
      background: #313131
  span.selected
    color: white
    background: $accent
    span.badge
      background: white
      color: $accent
  //&.shake


[dir=rtl] .SizesSelector>span
  &:first-of-type
    border-radius:  0 15px  15px 0
  &:last-of-type
    border-radius: 15px 0 0 15px

@media(max-width: 994px)
  .GoodModal
    .footer
      h3
        font-size: 32px
        padding: 3px
      p
        font-size: 20px

  .SizesSelector
    &>span
      font-size: 16px
      padding: 5px 8px
    span.badge
      font-size: 12px
      padding: 2px
      margin-top: 4px
      margin-left: 3px
      height: 12px
      width: 12px
      color: white
      background: $accent
      border-radius: 12px
      text-align: center
      line-height: 12px
