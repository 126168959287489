@import '../common'

.Button
  background-color: $accent
  font-size: 24px
  font-family: "Open Sans", sans-serif
  font-weight: 600
  text-transform: uppercase
  border-radius: 10px
  color: white
  padding: calc(0.75rem + 2px) 1.25rem
  box-sizing: border-box
  cursor: pointer
  border: none
  text-align: center
  line-height: 24px
  transition: background-color 0.2s,  color 0.2s
  margin: 5px 0


  &:hover
    background-color: darken($accent, 10%)
  &:active
    background-color: darken($accent, 20%)

  &.outline
    padding: 0.75rem  1.25rem

    background-color: transparent
    color: $accent
    border: 2px solid $accent
    &:hover
      border-color: darken($accent, 10%)
      background-color: darken($accent, 10%)
      color: white
    &:active
      background-color: darken($accent, 20%)
      border-color: darken($accent, 20%)


@media(max-width: 994px)
  .Button
    font-size: 18px
    line-height: 18px
    display: block !important

a.Button
  text-decoration: none
  margin: 0
  padding: 10px 19px
  display: inline