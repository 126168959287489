@import "../../common"
.OrderPage
  img
    width: 640px
    max-width: 75vw
    margin: 30px 0
  h4
    text-align: center
    color: white
    font-size: 36px
    font-weight: lighter
    padding: 5px 20px
    margin-inline: 0
    margin-block: 0
    margin: 20px 0
    b
      font-weight: bold
      &.contacts-link
        cursor: pointer
        color: $accent


@media(max-width: 991px)
  .OrderPage
    img
      margin: 15px 0

    h4
      font-size: 28px

