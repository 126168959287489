@import "../../common"

.CartPage
  h3
    @include sectionHeader
  h6.delivery-text
    color: #9d9d9d
    font-size: 20px
    text-align: center
    margin: 0
    padding: 0 20px
    margin-inline: 0
    margin-block: 0

  .Button
    border-radius: 0 10px 10px 0
  .nameInput
    border: 1px solid $accent
    border-radius: 12px
    display: flex
    align-content: stretch

    input
      height: calc(100% - 10px)
      background: transparent
      outline: none
      border: none
      color: white
      font-size: 22px
      padding: 5px 20px
      transition: color 150ms ease-in-out
      &::placeholder
        color: #505050
      &.invalid
        color: rgb(241, 29, 76)
    .col-lg:first-of-type
        border-right: 1px solid $accent

  .CartItem
    border-radius: 5px
    color: white
    padding: 20px
    background: #252525
    margin-bottom: 30px
    box-shadow: 6px 6px 12px 0 rgba(0, 0, 0, 0.45)
    transition: box

    h5
      margin-block: 0
      margin-inline: 0

    h5.title
      font-size: 38px
    span.size
      font-size: 38px
      font-weight: lighter
    span.price
      font-size: 38px
      font-weight: bolder
      text-align: center

    span.priceForQty
      font-size: 38px
      font-weight: bolder
      text-align: center
      flex-grow: 0
    img.img
      border-radius: 5px
      //aspect-ratio: 1
      width: 240px
    div.missingQty
      margin-right: auto
      margin-left: 10px
      &>span
        color: #ca1236

    [dir=rtl] div.missingQty
      margin-left: auto
      margin-right: 10px


@media(max-width: 994px)
  .CartPage
    .CartItem
      margin: 0 10px 15px 10px
      border-radius: 5px
      padding: 10px
      img.img
        width: 120px
        border-radius: 5px
      h5.title
        font-size: 20px
      span.size
        font-size: 24px
        font-weight: lighter
      span.price
        font-size: 24px
        font-weight: bolder
        text-align: center

      span.priceForQty
        font-size: 32px
        font-weight: bolder
        text-align: center
      .priceRow
        width: 100%

[dir=rtl]
  .CartPage
    .nameInput
      .col:first-of-type
        input

          border-right: none
          border-left: 1px solid $accent
    .Button
      border-radius:  10px 0 0 10px


@media(max-width: 991px)
  .CartPage
    .nameInput
      border: none
      flex-wrap: wrap
      .col-lg:first-of-type
        border-right: 1px solid transparent
      input
        height: calc(100% - 10px - 1.75em)
        width: calc(100% - 1em - 2px)
        padding: 0.75em 0.5em
        margin: 10px 0
        display: block
        flex-grow: 1
        border: 1px solid $accent
        border-radius: 10px

        &::placeholder
          color: #505050
      .Button
        border-radius:  10px
        margin-top: 10px