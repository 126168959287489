@import '../../common'
.CategoriesRow
  span
    color: $accent
    font-size: 32px
    font-weight: bolder
    cursor: pointer
    &.active
      color: white

@media(max-width: 994px)
  .CategoriesRow
    span
      font-size: 24px