@import '../common'


.Artist
  cursor: pointer
  padding: 15px
  display: flex
  flex-direction: column
  width: 160px
  align-items: center
  .portrait
    border-radius: 50%
    height: 140px
    width: 140px
    border: 2px solid $accent
  h4
    color: white
    font-size: 24px
    font-weight: 600
    text-align: center
    margin: 10px -10px
  h5
    color: white
    font-size: 20px
    font-weight: 300
    text-align: center
    margin: 0

.mobile-show
  .ArtistsSection
    .artists-container
      .Artist
        padding: 20px 5px
        .portrait
          height: 170px
          width: 170px

//@media(max-width: 12600px)
//  .ArtistsSection
//    .artists-container
//      .Artist
//        width: 170px
//        padding: 10px 5px
//        .portrait
//          height: 150px
//          width: 150px
//        h4
//          font-size: 20px
//        h5
//          font-size: 18px
