@import "bootstrap/scss/functions"
@import "../../common"
@import "bootstrap/scss/variables"
@import "bootstrap/scss/mixins"

.PortfoliosSection
  background-color: black
  h3
    padding-top : 20px
    //padding-bottom : 20px
    @include sectionHeader
  h5.tab
    color: white
    text-align: center
    font-size: 28px
    margin-inline: 0
    margin-block: 0
    margin: 20px 0

    cursor: pointer
    &:not(.active)
      color: $accent
