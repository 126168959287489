.FontLoadingSplash
  position:  absolute
  left: 0
  top: 0
  z-index: 500
  bottom: 0
  right: 0
  backdrop-filter:  blur(10px)
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column
  & div
    & img
      padding: 50px
      width: 175px
      animation: wiggle 3s infinite running linear
.light  .FontLoadingSplash
  h1
    color: black

@keyframes wiggle
  0%
    transform: rotateZ(0deg)
  10%
    transform: rotateZ(10deg) translateY(-5px)
  20%
    transform: rotateZ(5deg)
  30%
    transform: rotateZ(-10deg) translateY(-20px)
  40%
    transform: rotateZ(8deg)
  50%
    transform: rotateZ(-3deg)
  60%
    transform: rotateZ(05deg)
  70%
    transform: rotateZ(-10deg) translateY(-25px)
  80%
    transform: rotateZ(08deg)
  90%
    transform: rotateZ(-15deg) translateY(-10px)