.WelcomeSection .highlight
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  margin: 30px 0
  img
    width: 150px
    height: 150px
  h2,p
    color: white
    text-align: center

  h2
    padding-left: 15px

    font-size: 28px
  p
    font-size: 20px
    padding-left: 15px

@media(max-width: 992px)
  .WelcomeSection .highlight
    flex-direction: row
    div
      padding-left:  5px
    img
      width: 90px
      height: 90px
