@import "bootstrap/scss/functions"
@import "../../common"
@import "bootstrap/scss/variables"
@import "bootstrap/scss/mixins"

.ArtistsSection
  h3
    @include sectionHeader
  .artists-container
    display: flex
    justify-content: center
    flex-wrap: wrap

    &::after
      content: ""
      // flex: auto
  .mobile-row
    display: flex
    overflow-x: auto
    flex-wrap: nowrap
    //h3
      //display: none
    .Artist
      width: 140px !important

@include media-breakpoint-up(lg)
  .ArtistsSection
    .desktop-show
      display: block

@include media-breakpoint-down(lg)
  .ArtistsSection
    .desktop-show
      display: none


@include media-breakpoint-down(lg)
  .ArtistsSection
    .mobile-show
      display: block

@include media-breakpoint-up(lg)
  .ArtistsSection
    .mobile-show
      display: none


@media(max-width: 994px)
.ArtistsSection
  .artists-container
    overflow-y: auto

