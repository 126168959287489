@import './Header'
.LangSelector
  display: flex
  height: 100%
  justify-content: center
  align-items: center

  cursor: pointer
  img.flag
    width: 28px
    height: 28px
    margin-bottom: -5px
  span
    color:  white
    padding: 0 0.5rem
    font-size: 24px
    img
      width: 16px
      height: 16px
      margin-bottom: 2px
  ul.lang-dd
    margin-left: -20px
    position: absolute
    padding: 0 10px
    border-radius:  0 0 10px 10px
    top: $headerHeight
    background: black
    transition: max-height 0.3s ease
    max-height: 0
    display: block
    overflow: hidden
    &>li
      list-style: none
    &.open
      max-height: 400px

@media(max-width: 994px)
  .LangSelector
    .lang-dd
      li
        font-size: 16px
        img
          width: 24px
          height: 24px
          padding: 0 2px
