@import '../../common'
.FontSelector
  position: relative
  display: flex
  align-items: center
  height: 100%
  .selector-wrapper
    padding: 0 15px
    border-bottom: 2px solid white
    flex-grow: 1
    display: flex
    cursor: pointer
    align-items: center
    &>img
      height: 32px
      width: 32px
      flex-grow: 0

  .selectedFont
    flex-grow: 1
    font-size: 48px

  .fontsDropdown
    position: absolute
    top: 60px
    left: -20px
    max-height: 0
    z-index: 5
    transition: max-height .3s ease-in-out, height 300ms ease-in-out, box-shadow 150ms ease-in-out
    overflow-y: scroll
    -ms-overflow-style: none  /* Internet Explorer 10+ */
    scrollbar-width: none  /* Firefox */
    width:  calc(100% + 40px)
    background: #000
    overflow-x: hidden
    &::-webkit-scrollbar
      display: none

    border-radius: 4px
    &.open
      max-height: 600px
      height: auto
      box-shadow: 7px 7px 50px 5px transparentize(#fff,0.75)
    .controlRow
      padding: 10px 20px
      display: flex
      justify-content: space-between
      border-bottom: 1px solid whitesmoke
      .abGroup
        cursor: pointer
        div
          font-size: 24px
          padding: 5px 12px
          border-radius: 15px
          font-weight: bold
          transition: color 0.2s ease-in-out , background-color 0.2s ease-in-out, border-color 0.2s ease-in-out
          &.selected
            background: white
            color: black
    .exampleInput
      background: none
      outline: none
      border: none
      border-bottom: 1px solid white
      width: 100%
      color: white
      font-size: 28px
      padding: 10px 20px

    .option
      padding: 10px 20px
      font-size: 32px
      cursor: pointer

@media (max-width: 996px)
  .FontSelector
    .fontsDropdown
      top: 50px
    .selectedFont
      flex-grow: 1
      font-size: 28px


.light .FontSelector
  .selectedFont
    color: black
  .selector-wrapper
    border-bottom: 2px solid black
    svg
      fill: white !important
  .fontsDropdown
    background: #fff
    &.open
      box-shadow: 7px 7px 50px 5px transparentize(#000,0.75)
    .controlRow
      border-bottom: 1px solid black
      .abGroup
        color: black

        div
          &.selected
            background: black
            color: white
    .exampleInput
      border-bottom: 1px solid black
      color: black

    .option
      color: black