.TextResizer
  display: flex
  flex-direction: row
  align-items: center
  margin: 0 0 0 12px
  border-bottom: 2px solid black
  span.icon
    padding: 8px
    margin-top: 4px
    transition: border-bottom-color 0.5s

  svg
    display: inline-block
    &.tIcon
      width: 32px
      height:  32px
    path
      transition: fill 0.5s
  .ddIcon
    padding-right: 15px
  .dropdown
    text-align: right
    min-width: 112px // 3 digits and 'pt'
    border: none
    font-size: 28px
    margin: 0 10px 0 0
    position: relative
    transition: border-bottom-color 0.5s, color 0.5s
    padding: 0
    background: transparent
    color: black

    &:focus-visible
      outline: none

    .dropdownBody
      max-height: 0
      transition:  max-height 0.3s
      overflow: auto
      position: absolute
      width: 122px
      right: -42px
      padding-right: 16px
      top: 50px
      background: white
      z-index: 10
      box-shadow: 7px 7px 20px 0 rgba(127, 127, 127, 0.3)
      &.open
        max-height: 250px

      .textSizeOption
        padding: 5px 15px
        font-size: 26px
        color: black
        text-align: right

.dark .TextResizer
  border-bottom: 2px white solid
  span.icon
    //border-bottom: 2px solid white
  .dropdown
    color: white
    //border-bottom: 2px solid white
    .dropdownBody
      background: black
      .textSizeOption
        color: white


@media (max-width: 994px)
  .TextResizer
    .dropdownBody .textSizeOption
      &:nth-last-of-type(1), &:nth-last-of-type(2), &:nth-last-of-type(3), &:nth-last-of-type(4), &:nth-last-of-type(5)
        display: none
