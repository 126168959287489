$disabled-color-light: grey
$disabled-color-dark: grey
.FontsPage
  height: calc(100vh - 60px)
  background-color: black
  display: flex
  color: white
  overflow: hidden
  transition: background-color 0.5s ease

  &.light
    background-color: white
    .font-size-selector, .font-width-selector
      .v-fonts-slider,.h-font-width-slider
        .thumb , .track
          background-color: #000

  .centrifier
    padding-top: 50px
    flex-direction: column
    justify-content: center
    align-items: center

  .main
    flex-grow: 1

  .font-size-selector
    height: 100%
    width: 50px
    //background-color: #ee1eff
    .v-fonts-slider
      height: 100%
      margin-left: 20px
      .thumb
        border-radius: 50%
        background-color: #ffffff
        width: 30px
        height: 30px
      .track
        width: 5px
        margin-left: 12.5px
        background-color: #ffffff
        border-radius: 5px

  .font-selector
    width: 100%
    height: 60px

  .font-width-selector
    width: 100%
    padding: 15px

    .h-font-width-slider
      width: 100%
      .thumb
        border-radius: 50%
        background-color: #ffffff
        width: 30px
        height: 30px
        margin: -12.5px
      .track
        height: 5px
        margin-left: 12.5px
        background-color: #ffffff
        border-radius: 5px

  .font-formatting
    width: 100%
    height: 60px
    display: flex
    align-items: center

  .showcase
    width: 100%
    max-width: 100%
    height: 55dvh
    display: flex
    overflow: hidden

    justify-content: center
    align-items: center

  #text-renderer
    width: 100%
    height: 100%
    text
      color: white
      fill: white

  .icon-group
    display: inline-flex
    margin: 2px 5px
    box-sizing: border-box
    &>div
      padding: 4px
      cursor: pointer
      border: 1px solid white
      transition: background-color 0.5s
      &[data-active=true]
        background-color: white
      &[data-disabled=true]
        background-color: $disabled-color-light
      &>svg
        width: 32px
        height: 32px
        display: block
        path
          transition: fill 0.5s
      &:first-of-type
        border-top-left-radius: 5px
        border-bottom-left-radius: 5px

      &:last-of-type
        border-top-right-radius: 5px
        border-bottom-right-radius: 5px


  &.light .icon-group
    &>div
      &[data-active=true]
        background-color: black
        &>svg path
            fill: white
      &[data-disabled=true]
        background-color: $disabled-color-dark
        &>svg path
          fill: $disabled-color-light
      &>svg
        path
          fill: black


  .icon-group
    &>div
      border: 1px solid black
      &[data-active=true]
        background-color: white
        &>svg
          path
            fill: black
      &[data-disabled=true]
        background-color: $disabled-color-light
        &>svg path
          fill: $disabled-color-dark
      &>svg
        width: 32px
        height: 32px
        display: block
        path
          transition: fill 0.5s
          fill: white

  &.dark .icon-group > div
    border: 1px solid white
@media(max-height: 994px)

  .FontsPage
    .centrifier
      padding-top: 25px
    .showcase
      height: 40vh


