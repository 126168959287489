@import "../common"

$headerHeight: 60px

.Header
  z-index: 50
  position: sticky
  top: 0
  height: $headerHeight
  background: #000

  .content
    display: flex
    width: 100%
    flex-grow: 1
    justify-content: space-between
    //max-width: 968px
    max-height: $headerHeight
    align-items: center

  span.logo>img
    margin-top: 40px
    margin-left: 5px
    width: 70px

    position: relative
    z-index: 5

    transition: transform 0.1s ease-in
    cursor: pointer
    &:hover
      transform: scale(1.25) translateY(5%)

  ul
    list-style: none
    display: flex
    margin: 0
  ul li, span.mobile-nav, span.indexNav ,a
    padding: 0 1.25rem
    font-size: 25px
    color: white
    line-height: $headerHeight
    cursor: pointer
    text-transform: uppercase
    text-decoration: none
    text-align: center
    &.active
      border-bottom: $accent 3px solid
  span.indexNav
    min-width: 180px
  li a
    height: calc($headerHeight - 3px)
    display: block
  span.mobile-nav
    padding: 0 1rem
    font-size: 22px

  .show-mobile
    position: relative
  .dropdown
    position: absolute
    width: 17em
    overflow: hidden
    &.centered
      top: $headerHeight
      left: 50%
      transform: translateX(-50%)
    transition: max-height 0.5s ease-out
    display: none
    //height: 0
    background-color: black
    max-height: 0
    padding: 0
    border-bottom: 1px solid
    border-bottom-color: rgba(0,0,0,0)

    li
      padding: 0 30px
      cursor: pointer
      transition: border-bottom-color 0.2s
    &.show
      max-height: 450px
      display: block
      height: auto
  li.active
    border-bottom-color: $accent
@media(max-width: 994px)
  .Header
    .show-desktop
      display: none!important
  .Header
    .show-mobile
      display: flex!important

@media(min-width: 994.01px)
  .Header
    .show-desktop
      display: flex !important
  .Header
    .show-mobile
      display: none !important

@media (max-width: 1200px) and (min-width: 994px)
  .Header
    ul li, span.mobile-nav, a,span,span.indexNav
      font-size: 20px
    span.indexNav
      min-width: 150px


