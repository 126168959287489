@import "bootstrap/scss/functions"
@import "../common"
@import "bootstrap/scss/variables"
@import "bootstrap/scss/mixins"

.PortfolioGallery
  position: relative
  display: flex
  flex-wrap: wrap
  .load-next
    display: flex
    justify-content: center
    align-items: center
    background: linear-gradient(to top, rgba(0,0,0,1) 0%,rgba(0,0,0,1) 10%,rgba(0,0,0,0.9) 33%,rgba(0,0,0,0.6) 77%, rgba(0,0,0,0))
    position: absolute
    bottom: 0
    width: 100%
    height: 360px
    img
      cursor: pointer
      padding: 50px 20px 20px 20px
      width: 70px
      height: 70px

@include media-breakpoint-down(md)
  .PortfoliosSection
    .load-next
      height: 200px

.DesignsGallery

  img.artist
    position: absolute
    bottom: 5px
    right: 5px
    border-radius: 50%
    border: 2px solid $accent
    aspect-ratio: auto
    width: 40px
    filter: grayscale(50%)

@include media-breakpoint-down(lg)

  .DesignsGallery img.artist
    width: 30px
  @include media-breakpoint-down(xl)

  .DesignsGallery img.artist
    width: 30px
