.shake
  animation: shake 0.5s
$rotationMax:5deg
@keyframes shake
  0%
    transform: rotateZ(calc(1 * $rotationMax))
  10%
    transform: rotateZ(calc(-1 * $rotationMax))
  20%
    transform: rotateZ(calc(0.7 * $rotationMax))
  30%
    transform: rotateZ(calc(-0.7 * $rotationMax))
  40%
    transform: rotateZ(calc(0.5 * $rotationMax))
  50%
    transform: rotateZ(calc(-0.5 * $rotationMax))
  60%
    transform: rotateZ(calc(0.3 * $rotationMax))
  70%
    transform: rotateZ(calc(-0.3 * $rotationMax))
  80%
    transform: rotateZ(calc(0.1 * $rotationMax))
  90%
    transform: rotateZ(calc(-0.1 * $rotationMax))
